import { create } from "zustand";
import { GewerkCode, QuestionizerConfig, QuestionizerInputGewerk, QuestionizerInputType } from "./questionizer-config.ts";
import { FDWAntragsteller, FDWResult, FDWResultEntry, FDWResultResponse } from "../../../restfd2/fdw_result.ts";
import { FDWGid, Gemeinde } from "../../../restfd2/fdw_gid.ts";
import translation, { TranslationTable } from "../translation/translation.ts";
import { FDWTextblock, Textblock } from "../../../restfd2/fdw_textblock.ts";
import { FDWFp, FDWFpResponse} from "../../../restfd2/fdw_fp.ts";
import { FDWPdfLink} from "../../../restfd2/fdw_pdf_link.ts";
import { FDWSession } from "../../../restfd2/fdw_session.ts";
import { FDWStatAccesstime} from "../../../restfd2/fdw_stat_accesstime.ts";
import { FDWSendFormdata} from "../../../restfd2/fdw_send_formdata.ts";
import { FDWStatItem} from "../../../restfd2/fdw_stat_item.ts";
import { Evu, FDWEvuid } from "../../../restfd2/fdw_evuid.ts";
import { persist, createJSONStorage } from 'zustand/middleware'
import { QuestionizerConfigSection } from "./questionizer-config.ts";

export interface PrintData{
  baseurl? : string;
  cover_url? : string;
  logo_url? : string;
  logo2_url? : string;
  entries: Array<FDWResultEntry>
  text_blocks: Record<FpId, Array<Textblock>>,
  fp_details: Record<FpId, FDWFpResponse>,
}

//import { StateStorage } from 'zustand/middleware'
// const hashStorage: StateStorage = {
//   getItem: (key): string => {
//     const searchParams = new URLSearchParams(location.hash.slice(1))
//     const storedValue = searchParams.get(key) ?? ''
//     return JSON.parse(storedValue)
//   },
//   setItem: (key, newValue): void => {
//     const searchParams = new URLSearchParams(location.hash.slice(1))
//     searchParams.set(key, JSON.stringify(newValue))
//     location.hash = searchParams.toString()
//   },
//   removeItem: (key): void => {
//     const searchParams = new URLSearchParams(location.hash.slice(1))
//     searchParams.delete(key)
//     location.hash = searchParams.toString()
//   },
// }

export interface ValidationEntry{
  valid: boolean,
  message: string,
}

export type FpId = string;
export interface QuestionizerState{
    configPath?: string,
    update_result: number,
    config?: QuestionizerConfig,
    step: number,
    step_progression: number,
    session_id?: string,
    session_id_timestamp?: number,
    plz?: string,
    gemeinde_liste?: Array<Gemeinde>,
    gemeinde?: Gemeinde,
    gemeinde_id?: number,
    gewerk: Record<number, boolean>,
    active_groups: Record<number, boolean>,
    active_group?: number,
    active_items_per_group?: Record<number, number>,
    gewerk_code: GewerkCode,
    gewerk_labels: Array<string>,
    typ?: "Altbau" | "Neubau",
    wohngebaeude?: boolean,
    gewerbegebaeude?: boolean,
    gemeinnuetzig?: boolean,
    gebaeude?: "Einfamilienhaus" | "Mehrfamilienhaus" | "",
    baujahr?: number,
    grundversorger?: boolean,
    antragsteller?: Array<FDWAntragsteller> ,
    antragsteller_detail?: FDWAntragsteller ,
    evu_ids?: Array<Evu>,
    evu1_id?: number,
    evu2_id?: number,
    lang?: "de" | "fr" | "it",
    nutzer?: string,
    result?: FDWResultResponse,
    validation_inputs: Record<string, ValidationEntry>,
    validation_sections: Array<ValidationEntry>,
    include_in_print: Record<string, boolean>,
    view_print: boolean,
    translation:TranslationTable,
    text_blocks?: Record<FpId, Array<Textblock>>,
    fp_details?: Record<FpId, FDWFpResponse>,
    pdf_link?: string,
    firstname?: string,
    lastname?: string,
    email?: string,
    tel?: string,
    dataprotection?: boolean,
    formDataSet?: boolean,
    hydation_seed: number,
    form_was_mailed?: Record<string, string>,

    getGewerkLabels: () => Array<string>,
    setFirstname: (value: string | undefined) => void,
    setLastname: (value: string | undefined) => void,
    setEmail: (value: string | undefined) => void,
    setTel: (value: string | undefined) => void,
    setDataprotection: (value: boolean | undefined) => void,

    setpdf_link: (value: string | undefined) => void,
    loadConfig: (value: string) => void,
    settext_blocks: (value: undefined | Record<FpId, Array<Textblock>>) => void,
    setfp_details: (value: undefined | Record<FpId, FDWFpResponse>) => void,

    setConfig: (value: QuestionizerConfig) => void,
    stepsCount: () => number,
    incrementStep: () => void,
    decrementStep: () => void,
    setStep: (value: number, progress: boolean) => void,
    setSessionId: (value: string) => void,
    requestSessionId: () => void, 
    setPlz: (value: string) => void, 
    setGemeindeListe: (value: Array<Gemeinde> | undefined) => void,
    setGemeinde: (value: Gemeinde | undefined) => void, 
    setGemeindeId: (value: number | undefined) => Promise<void>, 
    toggleGewerk: (id: number, value: boolean) => void,
    toggleGroup: (id: number, value: boolean) => void,
    setTyp: (value: "Altbau" | "Neubau") => void, 
    setWohngebaeude: (value: boolean) => void, 
    setGewerbegebaeude: (value: boolean) => void, 
    setGemeinnuetzig: (value: boolean) => void, 
    setGebaeude: (value: "Einfamilienhaus" | "Mehrfamilienhaus" | "") => void,  
    setBaujahr: (value: number) => void, 
    setGrundversorger: (value: boolean) => void, 
    setAntragsteller: (value: FDWAntragsteller | Array<FDWAntragsteller>) => void,
    setantragsteller_detail: (value : FDWAntragsteller | undefined)  => void,

    setEvu_ids: (value: Array<Evu>) => void, 
    setEvu1_id: (value: number) => void, 
    setEvu2_id: (value: number) => void, 
    setLang: (value: "de" | "fr" | "it") => void, 
    setNutzer: (value: string) => void, 
    setViewPrint: (value: boolean) => void,
    setResult: (value: FDWResultResponse | undefined) => void, 
    setValidation: (id: number, value: ValidationEntry) => void,
    updateValidation: () => void,
    setIncludeInPrint: (id: number, value: boolean) => void,
    setIncludeInPrintExclusive: (id: number) => void,
    getCurrentStepErrors: () => Array<string>,
    isCurrentStepValid: () => boolean,
	isInputTypeExistInPage: (obj: object, targetType: string) => object,
    isStepValid: (step: number) => boolean,
    requesttext_blocks: () => Promise<void>,
    requestpdf_link: () => Promise<void>,
    requestfp_details: () => Promise<void>,
    requestResult: ()=> Promise<void>,
	sendMail: ()=> Promise<void>,
	sendDataMail: ()=> Promise<void>,
    requestStatistics: ()=> Promise<void>,
    requestGemeindeId: ()=> Promise<void>,
    _loadConfig: ()=> Promise<void>,
    getPrintUrl: () => Promise<string>,
    getResultIncludedInPrint: () => undefined | Array<FDWResultEntry>,
    openPrintView: (id: number | undefined) => Promise<void>,
    updateHydrationSeed: () => void
  };
  

export const useQuestionizerStore = create<QuestionizerState>()(
  persist(
    (set, get) => ({
    hydation_seed: Math.random(),
    translation: translation,
    update_result: 0,
    config: undefined,
    step: 0,
    step_progression: 0,
    session_id: undefined,
    plz: undefined,
    gemeinde_liste: undefined,
    gemeinde: undefined,
    gemeinde_id: undefined,
    gewerk: {},
    active_groups: {},
    active_group: undefined,
    active_items_per_group: undefined,
    gewerk_code: [],
    gewerk_labels: [],
    typ: undefined,
    wohngebaeude: undefined,
    gewerbegebaeude: undefined,
    gemeinnuetzig: undefined,
    gebaeude: undefined,
    baujahr: undefined,
    grundversorger: undefined,
    antragsteller: undefined,
    antragsteller_detail: undefined,
    evu1_id : undefined ,
    evu2_id :undefined,
    lang: "de",
    nutzer: undefined,
    result: undefined,
    text_blocks: undefined,
    fp_details: undefined,
    validation_inputs: {},
    validation_sections: [],
    view_print: false,
    include_in_print: {},
    openPrintView: async (exclusive_id? : number) => {
      if(get().config?.use_pdf_download_for_single_items){
        await get().requestpdf_link();
      }else{
       /* const url = [`${get().config?.printurl}?baseurl=${ get().config?.baseurl}`,
                  `&coverurl=${get().config?.cover_url}`,
                  `&logourl=${ get().config?.logo_url}`,
                  `&logo2url=${ get().config?.logo2_url}`,
                  `&sessionid=${get().session_id}`,
                  `&fpids=${get().getResultIncludedInPrint()!.map(fp_entry => fp_entry.fp_id).join(',')}`,
                  `&gemeindeid=${get().gemeinde_id}`,
                  `&gewerk=${Object.keys(get().gewerk_code).map( key => ''+key+'--'+get().gewerk_code[parseInt(key)].join(',')).join(';')}`,
                  `&typ=${get().typ  || "Neubau"}`,
                  `&wohngebaeude=${get().wohngebaeude  || false}`,
                  `&gewerbegebaeude=${get().gewerbegebaeude  || false}`,
                  `&gemeinnuetzig=${get().gemeinnuetzig || false}`,
                  `&gebaeude=${get().gebaeude}`,
                  `&baujahr=${get().baujahr}`,
                  `&grundversorger=${get().grundversorger || ''}`,
                  `&antragsteller=${get().antragsteller  || ''}`,
                  `&evu1id=${get().evu1_id  || ''}`,
                  `&evu2id=${get().evu2_id  || ''}`,
                  `&lang=${get().lang  || 'de'}`,
                  `&nutzer=${get().nutzer || ''}`,
                  `&inprint=${exclusive_id ? exclusive_id : Object.keys(get().include_in_print).filter(key => get().include_in_print[key]).join(',')}`].join('');
        
*/		const config = get().config;        
		 
		 
		 const antragsteller = get().antragsteller;
	 	 const antragsteller_detail = get().antragsteller_detail;
		 const _antragsteller = (antragsteller?.length == 1) ? antragsteller[0] : antragsteller_detail;
		// console.log(antragsteller);
		// console.log(antragsteller_detail);
		// console.log(_antragsteller);
		 
		 const angabedaten = {
			"massnahmen" : get().getGewerkLabels().join(', '),
			"antragsteller" : FDWAntragsteller[_antragsteller!],
			"ort" : get().plz +" "+ get().gemeinde?.gemeinde_name,
			"gebaeude" : get().gebaeude,
			"typ" : get().typ  || "Neubau",
			"baujahr" : get().baujahr			
		 }
		 const data = {
			  baseurl: config?.baseurl,
			  coverurl: config?.cover_url,
			  logourl: config?.logo_url,
			  logo2url: config?.logo2_url,
			  sessionid: get().session_id,
			  fpids: get().getResultIncludedInPrint()!.map(fp_entry => fp_entry.fp_id).join(','),
			  gemeindeid: get().gemeinde_id,
			  gewerk: Object.keys(get().gewerk_code).map( key => ''+key+'--'+get().gewerk_code[parseInt(key)].join(',')).join(';'),
			  typ: get().typ  || "Neubau",
			  wohngebaeude: get().wohngebaeude  || false,
			  gewerbegebaeude: get().gewerbegebaeude  || false,
			  gemeinnuetzig: get().gemeinnuetzig || false,
			  gebaeude: get().gebaeude,
			  baujahr: get().baujahr,
			  grundversorger: get().grundversorger || '',
			  antragsteller: get().antragsteller,
			  evu1id: get().evu1_id  || '',
			  evu2id: get().evu2_id  || '',
			  lang: get().lang  || 'de',
			  nutzer: get().nutzer || '',
			  inprint: exclusive_id ? exclusive_id : Object.keys(get().include_in_print).filter(key => get().include_in_print[key]).join(','),
			  angabedaten: JSON.stringify(angabedaten)			  
			};
			
			      			
		  const windowOptions = 'toolbar=0,location=0,menubar=0';

		  const newWindow = window.open('','questionizer', windowOptions);

		  if (newWindow) {
			
			const form = document.createElement('form');
			form.method = 'POST';
			form.action = get().config?.printurl || '';
			form.target = 'questionizer'; 
			
			for (const key in data) {
			  if (data.hasOwnProperty(key)) {
				const input = document.createElement('input');
				input.type = 'hidden';
				input.name = key;
				//input.value = data[key];
				input.value = data[key as keyof typeof data] as string;
				form.appendChild(input);
			  }
			}

			console.log(form);
			document.body.appendChild(form);
						
							// Stelle sicher, dass das Fenster vollständig geladen ist, bevor das Formular gesendet wird
				setTimeout(() => {
				  form.submit(); // Sende das Formular
				  document.body.removeChild(form); // Entferne das Formular nach dem Senden
				}, 100); // Ein kurzes Timeout, um sicherzustellen, dass das Fenster geöffnet ist


		
		
		  } else {
			console.error('Das neue Fenster konnte nicht geöffnet werden. Popup-Blocker aktiv?');
		  }
  
      }
    },
    
    updateHydrationSeed: ()=>{
      set((_)=>({hydation_seed:Math.random()}));
    },
    getPrintUrl: async () => {
      console.error("getPrintUrl is DEPRECATED DONT USE");
      return (get().config?.publicurl || "") + "?printdata=" + btoa(JSON.stringify(get().getResultIncludedInPrint()));//((parent !== window) ? document.referrer : document.location) + "#print",
    },

    setFirstname: (value) => {
      set((_state) => ({firstname: value, formDataSet: true}));
    },
    setLastname: (value) => {
      set((_state) => ({ lastname: value, formDataSet: true}));
    },
    setEmail:(value) => {
      set((_state) => ({ email: value, formDataSet: true}));
    },
    setTel: (value) => {
      set((_state) => ({ tel: value, formDataSet: true}));
    },
    setDataprotection: (value) => {
      set((_state) => ({ dataprotection: value, formDataSet: true}));
    },
    setpdf_link: (value) => {
      set((_state) => ({ pdf_link: value}));
    },

    loadConfig: (value) => {
      set((_state) => ({ configPath: value}));
      get()._loadConfig();
    },
    _loadConfig: async ()=>{
      const config = await (await fetch(get().configPath!)).json();  
      get().setConfig(config as QuestionizerConfig);
    },
    settext_blocks: (value) => set((_state) => ({ text_blocks: value})),
    setfp_details: (value) => set((_state) => ({ fp_details: value})),
    setConfig: (value) => {
      // let validation_sections = value.layout
      // .flatMap((step)=>step.sections.map((_) => ({message: "", valid: false})));

      // let validation_inputs = value.layout.flatMap((step)=>step.sections.flatMap((section) => section.inputs.map((input) => input.id))).reduce((acc : Record<string, ValidationEntry>, id)=>{
      //   acc[id] = {message: "", valid: false};
      //   return acc;
      // },{});

      set((_state) => ({ 
        config: value,
        ...(value.default ? value.default : {}),
        // validation_sections,
        // validation_inputs,
      }));
      //get().updateValidation();
    },
    
    stepsCount: () => get().config ? get().config!.layout.length : 0,
    incrementStep: () => get().setStep(Math.min(get().stepsCount(), get().step + 1), true),
    decrementStep: () => get().setStep(Math.max(0, get().step - 1), true),
    setStep: (value, progress) => {
      if (document !== undefined && document !== null){
        document.getElementById("febis-questionizer")?.scrollIntoView({ block: 'start',  behavior: 'smooth' });
      }
      let step = Math.min(Math.max(value, 0 ),get().stepsCount());
      if (!progress){
        step = Math.min(step, get().step_progression);
      }else{
        get().requestStatistics();
      }

      const step_progression = Math.max(step, get().step_progression);
      set((_) => ({ 
        step_progression,
        step
      }));

      get().updateValidation();
    },
    setSessionId: (value) => {
      console.log("session_id:",value);
      set((_state) => ({
        session_id_timestamp: Date.now ( ),
         session_id: value
        }))
    },
    requestSessionId: async () => {
        console.log("request new session_id");
		
		const mode = get().config!.mode;
	
		if(mode == 'live'){
			const session = await (await fetch('session.php')).json();  
			
			if(session)
				get().setSessionId(session!.session_id);
		    else
				console.error('live mode failed loading session');
		} else {
			const session_request = new FDWSession(
			  get().config!.apiuser,
			  get().config!.apipassword
			);
			const session = await session_request.request().catch((e) => {
				console.error(e);
			});	
			get().setSessionId(session!.session_id);			
		}		
    },
    setPlz: (value) => {
      set((_state) => ({ plz: value}));
      get().requestGemeindeId();
    },
    setGemeindeListe: (value) => set((_state) => ({ gemeinde_liste: value})),
    setGemeinde: (value) => set((_state) => ({ gemeinde: value})),
    setGemeindeId: async (value) => {
      set((_state) => ({ gemeinde_id: value}));
      if (value === undefined || get().session_id === undefined){ return; }
      try {
        const fdw_evuid_request = new FDWEvuid(get().session_id!, value!, [1,2,3,4,5]);
        const result = await fdw_evuid_request.request();
        if (result.length == 1){
          get().setEvu1_id(parseInt(result[0].id));
        }else{
		  if(get().config?.select_first_default_evu){
		     if( result[0].info == 'Grundversorger'){
				 get().setEvu1_id(parseInt(result[0].id));
			 }
		  }
		 	
          get().setEvu_ids(result);
        }
      } catch (error) {
        console.error(error);
      }
    },
    toggleGewerk: (id, value) => {
      let gewerk = {...get().gewerk};
      gewerk[id] = value;

      const input_gewerk_sets = get().config?.layout
        .flatMap(step => step.sections
        .flatMap(section => section.inputs))
        .filter(input => input.type == "gewerk")
        .reduce((acc : Record<number, GewerkCode>, input ) => ({...acc, ...{[input.id]: (input as QuestionizerInputGewerk).code}}),{});

    const gewerk_code = Object.keys(gewerk)
    .filter((iid : string) => gewerk[parseInt(iid)])
    .map((iid) => (input_gewerk_sets![parseInt(iid)])).reduce((acc, code) => {
      for (const id in code){
        if (acc[id] === undefined){
          acc[id] = [...code[id]];
        }else{
          acc[id] = [...new Set([...acc[id], ...code[id]])];
        }
      }
      return acc;
    },{});

      return set((_state) => ({ 
        gewerk, 
        gewerk_code,
        gewerk_labels: get().getGewerkLabels()
      }))
    },

    toggleGroup: (id, value) => {
      const active_groups = {...get().active_groups};
      active_groups[id] = value;

      return set((_state) => ({ 
        active_group: value ? id : undefined,
        active_groups, 
      }));
    },

    getGewerkLabels: () => get().config == undefined ? [] : get().config!.layout
    .flatMap(step => step.sections
    .flatMap(section => section.inputs))
    .filter(input => input.type == "gewerk")
    .filter(input => get().gewerk[input.id])
    .map(input => (input as QuestionizerInputGewerk).label),

    setViewPrint: (value) => set((_state) => ({ view_print: value})),
    setTyp: (value) => {
      const currentYear = new Date().getFullYear(); 
      if (value === "Neubau" && get().baujahr === undefined || get().baujahr! < currentYear){
        set((_state) => ({ baujahr: currentYear}));
      }
      set((_state) => ({ typ: value}));
    },
    setWohngebaeude: (value) => set((_state) => ({ wohngebaeude: value})),
    setGewerbegebaeude: (value) => set((_state) => ({ gewerbegebaeude: value})),
    setGemeinnuetzig: (value) => set((_state) => ({ gemeinnuetzig: value})),
    setGebaeude:(value) => set((_state) => ({ gebaeude: value})),
    setBaujahr: (value) => {
      const currentYear = new Date().getFullYear(); 
      if (value == currentYear){
        get().setTyp("Neubau")
      }else{
        get().setTyp("Altbau")
      }
      set((_state) => ({ baujahr: value}));
    },
    setGrundversorger: (value) => set((_state) => ({ grundversorger: value})),
    setAntragsteller: (value) => {
      set((_state) => ({ 
        antragsteller: Array.isArray(value) ? value : [value],
        antragsteller_detail: undefined,
      }));
    },
    setantragsteller_detail: (value) => set((_state) => ({ antragsteller_detail: value})),
    setEvu_ids: (value) => set((_state) => ({ evu_ids: value})),
    setEvu1_id: (value) => set((_state) => ({ evu1_id: value})),
    setEvu2_id: (value) => set((_state) => ({ evu2_id: value})),
    setLang:(value) => set((_state) => ({ lang: value})),
    setNutzer: (value) => set((_state) => ({ nutzer: value})),
    setResult: (value) => set((_state) => ({ result: value})),
    setValidation: (id, value) => {
      const validation_inputs = {...get().validation_inputs};
      validation_inputs[id] = value;

      set((_state) => ({ 
        validation_inputs,
      }));

      get().updateValidation;
    },
    updateValidation: () => {
      const error_plz = get().translation.errors['input-plz--default'];
      const error_antragsteller = get().translation.errors['input-antragsteller--default'];
      const error_gewerk = get().translation.errors['input-gewerk--default'];
      //const error_altbau_neubau = get().translation.errors['input-altbau-neubau--default'];
      const error_baujahr = get().translation.errors['input-baujahr--default'];
      const error_gebaeudetyp = get().translation.errors['input-gebaeudetyp--default'];
      //const error_gemeinnuetzig = get().translation.errors['input-gemeinnuetzig--default'];
      const error_gewerbegebaeude = get().translation.errors['input-gewerbegebaeude--default'];
      const error_wohngebaeude = get().translation.errors['input-wohngebaeude--default'];
      //const error_grundversorger = get().translation.errors['input-grundversorger--default'];
      //const active_groups = get().active_groups;
      const active_group = get().active_group;

      if (!get().config) {return;}

      const sections : Array<QuestionizerConfigSection> = get().config!.layout.flatMap(page => page.sections);
      const inputs : Array<QuestionizerInputType> = sections
      //.filter(section => (section.group != undefined ? active_group == section.group : true ))
      .flatMap(section => {
          return section.inputs;
        });   

      const validation_inputs_validation = inputs.map((input) => {
          switch (input.type){
          case 'plz': 
		    if (get().plz === undefined || get().gemeinde_id === undefined || get().plz!.length < 4){
              return {id:input.id, entry:{message:error_plz, valid: false}};
            }
            if (get().config?.country === "at" && get().plz!.length > 4){
              return {id:input.id, entry:{message:"Die Postleizahl muss für Österreich gültig sein (4 Stellen)", valid: false}};
            }
            break;
          case 'gewerk':
            if (!get().gewerk[input.id]){
              return {id:input.id, entry:{message:error_gewerk, valid: false}};
            }
            break;
          case 'altbau_neubau':
            break;
          case 'gebaeude_typ':
            if (get().gebaeude === undefined){
              return {id:input.id, entry:{message:error_gebaeudetyp, valid: false}};
            }
            break;
          case 'gebaeude_typ_3':
            if (get().gebaeude === undefined){
              return {id:input.id, entry:{message:error_gebaeudetyp, valid: false}};
            }
            break;
          case 'baujahr':{
            const currentYear = new Date().getFullYear(); 
            
            if (!get().baujahr || get().baujahr?.toString().match(/\d{4}/) === null){
              return {id:input.id, entry:{message:error_baujahr, valid: false}};
            }
            if (get().baujahr! > currentYear + 10){
              return {id:input.id, entry:{message:"Baujahr darf nicht mehr als 10 Jahre Zukunft liegen", valid: false}};
            }
            if (get().baujahr! < currentYear - 2000){
              return {id:input.id, entry:{message:"Baujahr darf nicht mehr als 2000 Jahre in der Vergangenheit liegen", valid: false}};
            }
            break;
          }
          case 'grouptoggle':
            return {id:input.id, entry:{message:"", valid: false}};
          case 'grundversorger':
            // if (false){
            //   return [input.id, {message:error_grundversorger, valid: false}];
            // }
            break;
          case 'antragsteller':
            if (get().antragsteller?.length == 0){
                return {id:input.id, entry:{message:error_antragsteller, valid: false}};
            }
            break;
          case 'antragsteller-select':
            if (get().antragsteller !== undefined && get().antragsteller![0] !== FDWAntragsteller["Natürliche Personen"] && get().antragsteller_detail === undefined){
              return {id:input.id, entry:{message:error_antragsteller, valid: false}};
            }
            break;
          case 'evu':
            // if (false){
            //   return [input.id, {message:error_evu, valid: false}];
            // }
            break;
          case 'gemeinnuetzig':
            // if (false){
            //   return [input.id, {message:error_gemeinnuetzig, valid: false}];
            // }
            break;
          case 'wohngebaeude':
            if (get().wohngebaeude === undefined){
              return {id:input.id, entry:{message:error_wohngebaeude, valid: false}};
            }
            break;
          case 'form': {
            const errors : Array<string> = [];

            if(input.firstname == "required" && !get().firstname){
              errors.push("Vorname");
            }
            if(input.lastname == "required" && !get().lastname){
              errors.push("Nachname");
            }
            if (input.email == "required" && 
                ( !get().email || !(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(get().email ?? "")))
              ){
              errors.push("E-Mail-Adresse");
            }
            if (input.tel == "required" && !get().tel){
              errors.push("Telefonnummer");
            }
            if ((input.dataprotection_b) && !get().dataprotection){
              errors.push("Zustimmung zur Datenschutzerklärung");
            }

            if (errors.length > 0){
              return {id:input.id, entry:{message:"Bitte "+errors.join(',')+" angeben.", valid: false}};
            }
            break;
          }
          case 'gewerbegebaeude':
            if (get().gewerbegebaeude === undefined){
              return {id:input.id, entry:{message:error_gewerbegebaeude, valid: false}};
            }
            break;
        }
        return {id:input.id, entry:{message:"", valid: true}};
      });


     const validation_inputs = validation_inputs_validation.reduce((acc: Record<string, ValidationEntry>, input : {id:number, entry:ValidationEntry}) => {
        acc[input['id']]=input['entry'];
        return acc;
      },{});

      const active_items_per_group: Record<number, Record<number, number>> = {};
      sections.filter((section) => section.groups !== undefined).forEach((section) => section.groups?.forEach((i)=> active_items_per_group[i] = {}));

      let group_validation_added = true;
      while (group_validation_added){
        group_validation_added = false;
        sections.filter(section => section.groups != undefined)
        .forEach(section => {
            section.inputs
            .filter(input => validation_inputs[input.id] && validation_inputs[input.id].valid)
            .forEach(input => {
              if(input.type == "grouptoggle"){
                let count = 0;
                for (const key in active_items_per_group[input.id]){
                  count += active_items_per_group[input.id][key];  
                }
                active_items_per_group[section.groups![0]][input.id] = count;
              }else{
                 active_items_per_group[section.groups![0]][input.id] = 1;
              }
          })});

        //Validate Grouptoggles
        inputs.filter(input => input.type == "grouptoggle").forEach(input => {
          //validation_inputs[input.id] = {message:error_group_toggle, valid: false};
          const r = active_items_per_group[input.id];
          if (r == undefined){ return; }
          if ((Object.keys(r).length > 0) && (validation_inputs[input.id].valid == false)){
            validation_inputs[input.id] = {message:"", valid: true};
            group_validation_added = true;
          }
        });
      }
      

      const validation_sections = get().config?.layout[get().step].sections
      .filter(section => (section.groups != undefined ? section.groups.includes(active_group ?? -1) : true ))
      .map(section => {
        if (section.validation === "none"){
          return {
            valid: true, 
            message: "",
          };
        }else if (section.validation === "or"){
          const valid = section.inputs.map(input => input.id).some(id => (validation_inputs[id] && validation_inputs[id].valid));
          return {
            valid, 
            message: (!valid && section.hint !== undefined ? section.hint : "")
          };
        } else {
          return {valid: section.inputs.map(input => input.id).every(id => (validation_inputs[id] && validation_inputs[id].valid)), message: [...new Set(section.inputs.map(input => validation_inputs[input.id].message))].join(', ')};
        }
      });

      const activeItemsCountPerGroup: Record<number, number> = {};
      for (const section_id in active_items_per_group){
        if(active_items_per_group[section_id] !== undefined){
          let count = 0;
          for (const input_id in active_items_per_group[section_id]){
            count += active_items_per_group[section_id][input_id];  
          }
          activeItemsCountPerGroup[section_id] = count;
        }
       
      }

      set((_state) => ({ 
        active_items_per_group: activeItemsCountPerGroup,
        validation_inputs,
        validation_sections,
      }))
    },
    setIncludeInPrint: (id, value) => {
      const x = {...get().include_in_print};
      x[id] = value;
      set((_state) => ({ include_in_print: x}))
    },
    setIncludeInPrintExclusive: (id) => {
      set((_state) => ({ include_in_print: {[id]: true}}))
    },
    isCurrentStepValid: () => {
      return get().isStepValid(get().step);
    },
	isInputTypeExistInPage :(obj: any, targetType: string) => {
		// Check if the object is an array
		if (Array.isArray(obj)) {
		  for (let item of obj) {
			const result = get().isInputTypeExistInPage(item, targetType); // Recursively search in array
			if (result) return result; // If result found, return it
		  }
		}
		// Check if the object is an object
		else if (typeof obj === 'object' && obj !== null) {
		  // If the type matches the targetType, return the object
		  if ('type' in obj && obj.type === targetType) {
			return obj;
		  }
		  // Otherwise, iterate over the keys in the object
		  for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
			  const result = get().isInputTypeExistInPage(obj[key], targetType); // Recursively search in nested objects
			  if (result) return result; // If result found, return it
			}
		  }
		}
		return null; // Return null if no result is found
  },
    isStepValid: (step) => {
      if(step < get().stepsCount()) {
        return get().validation_sections.every(b => b.valid) || false;
      }else{
        return true;
      }
    },
    getCurrentStepErrors: () => {
      if(get().step < get().stepsCount()) {
        return [ ...(new Set(get().validation_sections.map(b => b.message)))];
      }else{
        return [];
      }
    },

    getResultIncludedInPrint: () => {
      let entries = get().result?.filter(result_item => get().include_in_print[result_item.fp_id]);
      if(!entries || entries.length == 0){
        entries = get().result;
      }
      return entries;
    },

    requestGemeindeId: async () => {
      const session_id = get().session_id;
      const plz = get().plz;

      if (session_id == undefined || plz == undefined || plz.length < 4){
        set((_)=>({
          gemeinde_id: undefined,
          gemeinde: undefined,
          gemeinde_liste: undefined,
        }));
        get().updateValidation();
        return;
      }

      try {
        const fdw_gid_request = new FDWGid(session_id,plz);
        const result = await fdw_gid_request.request();
          
        if (!result || result.length == 0){
          set((_)=>({
            gemeinde_id: undefined,
            gemeinde: undefined,
            gemeinde_liste: undefined,
          }));
          get().updateValidation();
          return;
        }
      
        if (result.length == 1){
          set((_)=>({
            gemeinde_id: result[0].gemeinde_id,
            gemeinde: result[0],
            gemeinde_liste: undefined,
          }));
		  get().setGemeindeId(result[0].gemeinde_id);
        }else{
          set((_)=>({
            gemeinde_id: undefined,
            gemeinde: undefined,
            gemeinde_liste: result,
          }));
        }
      } catch (error) {
        set((_)=>({
          gemeinde_id: undefined,
          gemeinde: undefined,
          gemeinde_liste: undefined,
        }));
        console.error(error as Error);
      }
      get().updateValidation();
    },

    requestfp_details: async () => {
      const session_id = get().session_id;
      const result = get().result;
      const include_in_print = get().include_in_print;

      if (!result || !session_id){ return }
      
      get().setfp_details({});

      let fp_ids = result.filter(result_item => include_in_print[result_item.fp_id]).map(fp_entry => fp_entry.fp_id);
      if(fp_ids.length == 0){
        fp_ids = result.map(fp_entry => fp_entry.fp_id);
      }

      const requests = fp_ids.map(fp_id => {
        const request = new FDWFp(session_id, fp_id);
        return request.request();
      })
      
      try {
        const results = await Promise.all(requests);
        get().setfp_details(fp_ids.reduce((acc : Record<number, FDWFpResponse>, fp_id, i) => {
          acc[fp_id] = results[i];
          return acc; 
        },{}));
      } catch (error) {
        console.error(error);
      }  
    },
	sendMail:async ()=>{
		 get().sendDataMail();
	},
	sendDataMail: async ()=>{
		try {
		 const antragsteller = get().antragsteller;
	 	 const antragsteller_detail = get().antragsteller_detail;
		 const _antragsteller = (antragsteller?.length == 1) ? antragsteller[0] : antragsteller_detail;
	 
          const formData = {
              Vorname: get().firstname || "",
              Name: get().lastname || "",
              Email: get().email || "",
              Telefon: get().tel || ""
            };
				
          if (get().config?.form_email_to && get().formDataSet && (JSON.stringify(formData) !== JSON.stringify(get().form_was_mailed))){
			
			const formDataExt = {...formData} as any;
			if(get().config?.add_extended_data_craft_in_email){
				formDataExt['Gewählte Maßnahmen']  = get().getGewerkLabels().join(', ') ;
				formDataExt['Antragsteller'] = FDWAntragsteller[_antragsteller!];
				formDataExt['Ort'] = get().plz +" "+ get().gemeinde?.gemeinde_name ;
				formDataExt['Gebäude'] = get().gebaeude;
				formDataExt['Typ'] = get().typ  || "Neubau";
				formDataExt['Baujahr'] = get().baujahr;
			 }
			  
            const resultSendFormdata = new FDWSendFormdata(
              get().session_id!,
              get().config?.form_email_to!,
              formDataExt,
              get().config?.form_email_cc,
              get().config?.form_email_subject,
            );
            await resultSendFormdata.request();
            set((_)=>({form_was_mailed: formData}));
          }
        } catch (error) {
          console.error(error);
          get().setResult(undefined);
        }
	},
    requestResult: async ()=>{
        if (get().session_id === undefined){
          return;
        }
		get().sendDataMail();
        get().setResult(undefined);
        try{
          const resultStatItem = new FDWStatItem(
            get().session_id!,
            get().getGewerkLabels(),
          );

          await resultStatItem.request();
        } catch (error) {
          console.error(error);          
        }

        try{
          const _antragsteller = (get().antragsteller !== undefined && get().antragsteller?.length == 1) ? get().antragsteller![0] : get().antragsteller_detail;
          const request = new FDWResult(
            get().session_id!,
            get().gemeinde_id!,
            get().gewerk_code,
            get().typ || 'Neubau',
            get().wohngebaeude || true,
            get().gewerbegebaeude || false,
            get().gemeinnuetzig || false,
            get().gebaeude || 'Einfamilienhaus',
            get().baujahr || 2000,
            get().grundversorger,
            _antragsteller,
            get().evu1_id, 
            get().evu2_id,
            get().lang,
            get().nutzer
          );
          const result = await request.request();
          
          if (get().config?.include_all_results_in_print!) {
            set((_state) => ({include_in_print: result.reduce((acc : Record<number, boolean>, entry) => {
              acc[entry.fp_id] = true;
              return acc;
              }, {})})
            );
          }
          
          get().setResult(result || undefined);
          
        } catch (error) {
          console.error(error);
          get().setResult(undefined);
        }
    },
    requestStatistics: async ()=>{
      if (get().session_id === undefined){
        return;
      }
      const request = new FDWStatAccesstime(get().session_id!, "Page-" + get().step);
      try {
        await request.request();
      } catch (error) {
         console.error(error as Error);
      }
  },
  requesttext_blocks: async () => {
      const session_id = get().session_id;
      const result = get().result;
      const include_in_print = get().include_in_print;
      
      if (!result || !session_id){ return }
      
      get().settext_blocks({});

      let fp_ids = result.filter(result_item => include_in_print[result_item.fp_id]).map(fp_entry => fp_entry.fp_id);
      
      if(fp_ids.length == 0){
        fp_ids = result.map(fp_entry => fp_entry.fp_id);
      }

      const requests = fp_ids.map(fp_id => {
        const fdw_textblock_request = new FDWTextblock(session_id!, fp_id, [1,2,3,4,5,6,7,8,9,10,11,12,14], "de");
        return fdw_textblock_request.request();
      })
      
      try {
        const results = await Promise.all(requests);
        set( _ => ({
          view_print: true,
          text_blocks: fp_ids.reduce((acc : Record<number, Array<Textblock>>, fp_id, i) => {
            acc[fp_id] = results[i];
            return acc; 
          },{})
        }));
      } catch (error) {
        console.error(error);
      }  
    },

    requestpdf_link: async () => {
      const session_id = get().session_id;
      const result = get().result;
      const include_in_print = get().include_in_print;

      if (!result || !session_id){ return }

      let fp_ids = result.filter(result_item => include_in_print[result_item.fp_id]).map(fp_entry => fp_entry.fp_id);
      if(fp_ids.length == 0){
        fp_ids = result.map(fp_entry => fp_entry.fp_id);
      }

      const fdw_pdf_request = new FDWPdfLink(session_id, fp_ids, undefined, undefined,undefined,undefined);
      
      try {
        set(_ => ({
          view_print: false,
          text_blocks:{},
          pdf_link: undefined,
        }));
  
        const result = await fdw_pdf_request.request();

        get().setpdf_link(result);

      } catch (error) {
        console.error(error);
      }  
    }
  }),
  {
    name: 'fbq-state-storage-'+window.location.host.replace(/[\./]/g,''),
    version: Date.now(),
    migrate: (persistedState, version) => {
      if (Date.now() - version > 86400000) {
        return {...persistedState as object,  
          hydation_seed: Math.random(),
          step: 0,
          step_progression: 0,
          plz: undefined,
          gemeinde_liste: undefined,
          gemeinde: undefined,
          gemeinde_id: undefined,
          gewerk: {},
          active_groups: {},
          active_group: undefined,
          active_items_per_group: undefined,
          gewerk_code: [],
          gewerk_labels: [],
          typ: undefined,
          wohngebaeude: undefined,
          gewerbegebaeude: undefined,
          gemeinnuetzig: undefined,
          gebaeude: undefined,
          baujahr: undefined,
          grundversorger: undefined,
          antragsteller: undefined,
          antragsteller_detail: undefined,
          evu1_id : undefined ,
          evu2_id :undefined,
          lang: "de",
          nutzer: undefined,
          result: undefined,
          text_blocks: undefined,
          fp_details: undefined,
          validation_inputs: {},
          validation_sections: [],
          view_print: false,
          include_in_print: {},
        }
      }
      return persistedState
    },
    onRehydrateStorage: (_state) => {
      return (state, error) => {
        async function init_request(){
          console.log("loading state:", 'fbq-state-storage-'+window.location.host.replace(/[\./]/g,''));
          await state!._loadConfig();
          //request a new Session if session_id is older than 1 day
          if(state!.session_id === undefined || state!.session_id_timestamp === undefined || (state!.session_id_timestamp + 43200000) < Date.now()){
            await state!.requestSessionId();
          }
          await state!.requestResult();
          state?.updateHydrationSeed();
          state?.updateValidation();
          if (error) {
            console.log('Fehler während der Hydration', error)
          } else {
            console.log('Hydration abgeschlossen', state!.hydation_seed)
          }
        }
        init_request();
      }
    },  
    partialize: (state) =>
      Object.fromEntries(
        Object.entries(state).filter(([key]) => 
          !['config', 'translation'].includes(key)
      )),
    storage: createJSONStorage(() => localStorage),
  })
);

// function sha256(ascii) {
//   function rightRotate(value, amount) {
//       return (value>>>amount) | (value<<(32 - amount));
//   };
  
//   const masthPow = Math.pow;
//   const maxWord = mathPow(2, 32);
//   let lengthProperty = 'length'
//   let i, j;
//   let result = '';

//   let words = [];
//   const asciiBitLength = ascii[lengthProperty]*8;
//   let hash = sha256.h = sha256.h || [];
//   const k = sha256.k = sha256.k || [];
//   const primeCounter = k[lengthProperty];

//   let isComposite = {};
//   for (var candidate = 2; primeCounter < 64; candidate++) {
//       if (!isComposite[candidate]) {
//           for (i = 0; i < 313; i += candidate) {
//               isComposite[i] = candidate;
//           }
//           hash[primeCounter] = (mathPow(candidate, .5)*maxWord)|0;
//           k[primeCounter++] = (mathPow(candidate, 1/3)*maxWord)|0;
//       }
//   }
  
//   ascii += '\x80'
//   while (ascii[lengthProperty]%64 - 56) ascii += '\x00'
//   for (i = 0; i < ascii[lengthProperty]; i++) {
//       j = ascii.charCodeAt(i);
//       if (j>>8) return;
//       words[i>>2] |= j << ((3 - i)%4)*8;
//   }
//   words[words[lengthProperty]] = ((asciiBitLength/maxWord)|0);
//   words[words[lengthProperty]] = (asciiBitLength)
  
//   for (j = 0; j < words[lengthProperty];) {
//       const w = words.slice(j, j += 16);
//       const oldHash = hash;
//       hash = hash.slice(0, 8);
      
//       for (i = 0; i < 64; i++) {
//           const i2 = i + j;
//           const w15 = w[i - 15], w2 = w[i - 2];

//           const a = hash[0], e = hash[4];
//           const temp1 = hash[7]
//               + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25))
//               + ((e&hash[5])^((~e)&hash[6]))
//               + k[i]
//               + (w[i] = (i < 16) ? w[i] : (
//                       w[i - 16]
//                       + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15>>>3))
//                       + w[i - 7]
//                       + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2>>>10))
//                   )|0
//               );
//           const temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22))
//               + ((a&hash[1])^(a&hash[2])^(hash[1]&hash[2]));
          
//           hash = [(temp1 + temp2)|0].concat(hash);
//           hash[4] = (hash[4] + temp1)|0;
//       }
      
//       for (i = 0; i < 8; i++) {
//           hash[i] = (hash[i] + oldHash[i])|0;
//       }
//   }
  
//   for (i = 0; i < 8; i++) {
//       for (j = 3; j + 1; j--) {
//           const b = (hash[i]>>(j*8))&255;
//           result += ((b < 16) ? 0 : '') + b.toString(16);
//       }
//   }
//   return result;
// };