import { useQuestionizerStore } from "../types/state";
import Errors from "./errors";

export default function StepButtons(){
  const step = useQuestionizerStore((state) => state.step);
  const stepsCount = useQuestionizerStore((state) => state.stepsCount());
  const incrementStep = useQuestionizerStore((state) => state.incrementStep);
  const decrementStep = useQuestionizerStore((state) => state.decrementStep);
  const isCurrentStepValid = useQuestionizerStore((state) => state.isCurrentStepValid());
  const isInputTypeExistInPage = useQuestionizerStore((state) => state.isInputTypeExistInPage);
  const formWasMailed = useQuestionizerStore((state) => state.form_was_mailed);
  const config = useQuestionizerStore((state) => state.config);
  const currentSections = config?.layout[step].sections;
  const nextSections = config?.layout[step+1]?.sections;
  const lastSections = config?.layout[stepsCount-1]?.sections;
 
  var showButtonKontakt = false;
  if(nextSections && (nextSections == lastSections) && isInputTypeExistInPage(lastSections ?? [],"form") ){
	showButtonKontakt = true;
  }


  return <div>
	  <div className='step_buttons'>
		{ step > 0 ? <div className="step_buttons__button btn step_buttons__back" onClick={decrementStep}><div className="step_buttons__icon icon-inline--left icon-left--white"></div><span>Zurück</span></div> : <div></div>}
		<Errors></Errors>
		
		{ (step < (stepsCount-1) && !showButtonKontakt ) ? <div className={"step_buttons__button btn step_buttons__next " + (isCurrentStepValid ? "" : "btn--disabled")} onClick={incrementStep}>
		  <span>Weiter</span><div className="step_buttons__icon icon-inline--right icon-right--white pl-4"></div>
		</div>  : <div></div>}
		
	
		{ currentSections && isInputTypeExistInPage(currentSections,"result") && <PrintButton /> }
		{ currentSections && (currentSections == lastSections) && isInputTypeExistInPage(currentSections,"form")  && !formWasMailed &&  <SendEmailButton /> }
	  </div>
	  <div className='pt-4'/>
	  <div className='pt-4'>
	  		{ showButtonKontakt ? <div className={" btn btn-full"} onClick={incrementStep}>
		   <span>{config?.text_btn_contact}</span><div className="step_buttons__icon  pl-4"></div>
		  </div>  : <div></div>}
	  </div>
   </div>
}

function PrintButton(){
  const include_in_print = useQuestionizerStore((state) => state.include_in_print);
  const openPrintView = useQuestionizerStore((state) => state.openPrintView);

  let print_list_empty = Object.keys(include_in_print).filter(key => include_in_print[key]).length > 0;

  return <div className={"step_buttons__button btn--print step_buttons__next " + (print_list_empty ? "btn" : "btn btn--disabled")} 
              onClick={ (_) => openPrintView(undefined) }>
                <div className="icon btn__icon_print"></div>
                <span>Auswahl Anzeigen / Drucken</span>
            </div>;
  }
  
function SendEmailButton(){
  const sendMail = useQuestionizerStore((state) => state.sendMail);
  return <div className={"step_buttons__button btn--print step_buttons__next btn"} 
              onClick={ (_) => sendMail() }>
                <span>Senden</span>
            </div>;
}