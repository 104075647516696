import { useQuestionizerStore } from "../types/state";

export default function StepNavigation (){
  const stepsCount = useQuestionizerStore((state) => state.stepsCount());
  const step = useQuestionizerStore((state) => state.step);

  let steps = [];
  for (let i = 0; i < stepsCount; i++){
    steps.push(<Step key={i} index={i} active={i === step}/>);
  }
  return <div className='step_navigation'>
    {steps}
  </div>
}

function Step(props: {index: number, active: boolean}){
  const setStep = useQuestionizerStore((state) => state.setStep);
  const step_progression = useQuestionizerStore((state) => state.step_progression);

  const point_disabled_style = " step__point step__point--disabled pointer-events-none ";
  const point_active_style = " step__point step__point--active ";
  const point_inactive_style = " step__point ";

  const line_disabled_style = " step_navigation__line step_navigation__line--disabled pointer-events-none ";
  const line_active_style = " step_navigation__line step_navigation__line--active ";
  const line_inactive_style = " step_navigation__line ";

  const reachable = props.index <= step_progression;
  return <div className={'step_navigation__step'} onClick={()=>{if(reachable){setStep(props.index, false)}}}>
      <div className={!reachable ? line_disabled_style : (props.active ? line_active_style : line_inactive_style)}></div>
      <div className={!reachable ? point_disabled_style : (props.active ? point_active_style : point_inactive_style)}>{props.index + 1}</div>
    </div>
}