import { useQuestionizerStore } from "../types/state.ts";
import Toggle from "./toggle.tsx";

export default function InputGroupToggle (props: {id: number, icon:string, icon_active?:string, label: string, info_title: string,info_text: string, deactivate_ids?:Array<number>}){
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);
  const active_group = useQuestionizerStore((state) => state.active_group);
  const toggleGroup = useQuestionizerStore((state) => state.toggleGroup);
  const toggleGewerk = useQuestionizerStore((state) => state.toggleGewerk);
  const active_items_per_group = useQuestionizerStore((state) => state.active_items_per_group);

  return <Toggle id={props.id.toString()} count={active_items_per_group ? active_items_per_group[props.id] : undefined} label={props.label} info_title={props.info_title} info_text={props.info_text} icon={props.icon} icon_active={props.icon_active} value={props.id == active_group} onChange={(value)=>{
      if (props.deactivate_ids !== undefined){
        props.deactivate_ids.forEach((id)=>{
          toggleGewerk(id, false);
        });
	  }
	  toggleGroup(props.id, value);
      updateValidation();
      document.querySelector(".group-"+props.id)?.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }}/>
}
