import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'

let configPath = document!.querySelector('meta[name="febis-questionizer-config-url"]')?.getAttribute('content');
if (configPath !== undefined) {
    ReactDOM.createRoot(document.getElementById('febis-questionizer')!).render(
    <React.StrictMode>
      <App configPath={configPath}/>
    </React.StrictMode>);
  }else{
    console.warn("Keine Konfiguration gefunden");
  }