import { BASEURL, Request, TRequestMethod } from "./request.ts";
import { Schema } from "./validation.ts";

/*
Der Webservice fdw_Statistics gibt eine für diese Anfrage eindeutige ID zurück, 
welche für die weiteren Webservices zu der jeweiligen Anfrage mitgegeben werden muss.
*/

export type FDWStatAccesstimeResponse = string;

export class FDWStatAccesstime extends Request<FDWStatAccesstimeResponse>{
    method : TRequestMethod = "POST";
    responseSchema : Schema = 'string';

    constructor (
        public session_id: string,
        public page: string){
        super();
    }

    route() : string{
        return `${BASEURL}/fdw_stat_accesstime/`;
    }
}