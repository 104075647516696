import { useQuestionizerStore } from "../types/state.ts";
import Select from "./select.tsx";

export default function InputEvu (props: {id: number, label: string}){
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);
  const evu_ids = useQuestionizerStore((state) => state.evu_ids);
  const evu1_id = useQuestionizerStore((state) => state.evu1_id);
  const setEvu1_id = useQuestionizerStore((state) => state.setEvu1_id);
  //const error_default = useQuestionizerStore((state) => state.translation.errors['input-evu--default']);
  const label = useQuestionizerStore((state) => state.translation.labels['input-evu']);

 if (evu_ids === undefined || evu_ids.length < 2){return <div></div>}
  return <div className={"input-antragsteller-select input-"+props.id}>
    {/* <label className={"input-antragsteller-select__label"}>{props.label}</label> */}
    <Select<number> label={label} value={evu1_id || -1} onChange={(value) => {
         setEvu1_id(value);
         updateValidation();
        }}>
        <option key={"default"} value={-1}>{label}</option>
        {evu_ids.map((evu_id)=><option value={evu_id.id} key={evu_id.id}>{evu_id.name}</option>)}
      </Select>
  </div>
}

// export default function InputEvu (props: {id: number, label: string}){
//   //const store = useQuestionizerStore((state) => state.session_id);
//   const setValidation = useQuestionizerStore((state) => state.setValidation);

//   return <div>
//     <label>{props.label}</label>
//     <input type="text" value={""} onChange={()=>{
//       setValidation(props.id, {message:"", valid: true});
//     }}/>
//   </div>
// }