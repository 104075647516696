import { useQuestionizerStore } from "../types/state.ts";
import Toggle from "./toggle.tsx";

export default function InputGebaeudeTyp (props: {
  id: number,
  label_einfamilienhaus?: string,   
  icon_einfamilienhaus: string, 
  icon_einfamilienhaus_active?: string,
  label_mehrfamilienhaus?: string,   
  icon_mehrfamilienhaus: string, 
  icon_mehrfamilienhaus_active?: string}){
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);
  const gebaeude = useQuestionizerStore((state) => state.gebaeude);
  const setGebaeude = useQuestionizerStore((state) => state.setGebaeude);

  const label_einfamilienhaus = props.label_einfamilienhaus || useQuestionizerStore((state) => state.translation.errors['input-gebaeudetyp--einfamilienhaus']);
  const label_mehrfamilienhaus = props.label_mehrfamilienhaus ||  useQuestionizerStore((state) => state.translation.errors['input-gebaeudetyp--mehrfamilienhaus']);

  return <div>
      <div className="section__inputs justify-center section__inputs--grid mb-6">
          <div className="hidden md:block sm:col-span-2"></div>
          <Toggle id={props.id+'-efh'} label={label_einfamilienhaus} icon={props.icon_einfamilienhaus}
                  icon_active={props.icon_einfamilienhaus_active} value={gebaeude == "Einfamilienhaus"}
                  onChange={(_) => {
                      setGebaeude("Einfamilienhaus");
                      updateValidation();
                      //setValidation(props.id, {message:"", valid: true});
                  }}/>
          <Toggle id={props.id+'-mfh'} label={label_mehrfamilienhaus} icon={props.icon_mehrfamilienhaus}
                  icon_active={props.icon_mehrfamilienhaus_active} value={gebaeude == "Mehrfamilienhaus"}
                  onChange={(_) => {
                      setGebaeude("Mehrfamilienhaus");
                      updateValidation();
                      //setValidation(props.id, {message:"", valid: true});
                  }}/>
      </div>
  </div>
}