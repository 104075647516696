import { BASEURL, Request, TRequestMethod } from "./request.ts";
import { Schema } from "./validation.ts";

export interface Textblock {
    id: number;
    name: string;
    content: string;
}

export type FDWTextblockResponse = Array<Textblock>;

export class FDWTextblock extends Request<FDWTextblockResponse>{
    method : TRequestMethod = "GET";
    responseSchema : Schema = [{
        id: "number",
        name: "string",
        content: "string"
    }]

    constructor (
        public session_id: string,
        public fp_id: number,
        public block_id: Array<number>,
        public language: "de" | "it" | "fr",
    ){
        super();
    }

    route() : string{
        return `${BASEURL}/fdw_textblock/${this.session_id}/${this.fp_id}/${this.block_id.join(',')}/${this.language}`;
    }
}