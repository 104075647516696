import { BASEURL, Request, TRequestMethod } from "./request.ts";
import { Schema } from "./validation.ts";

export type FDWPdfLinkResponse = string;

export class FDWPdfLink extends Request<FDWPdfLinkResponse>{
    method : TRequestMethod = "GET";
    responseSchema : Schema = "string"

    constructor (
        public session_id: string,
        public fp_ids: Array<number>,
        public url_logo?: string, //base64 codiert 
        public url_logo_left?: number,
        public url_logo_top?: number,
        public url_logo_width?: number,
    ){
        super();
    }

    route() : string{
        return `${BASEURL}/fdw_pdflink/${this.session_id}/${this.fp_ids.join(',')}/${this.url_logo && this.url_logo + "/"}${this.url_logo_left && this.url_logo_left + "/"}/${this.url_logo_top && this.url_logo_top + "/"}/${this.url_logo_width && this.url_logo_width + "/"}`;
    }
}