import { BASEURL, Request, TRequestMethod } from "./request.ts";
import { Schema } from "./validation.ts";

/*
*/


export interface FDWResultEntry{
    fp_id: number,
    fp_name: string,
    fp_betrag: string,
    fp_organisation: string,
    region: "Bundesweit verfügbare Programme",
    gewerke_id: string,
    kriterium_id: string,
    fp_type: string
}

export enum FDWEvuTyp{
    "Gas" = 1,
	"Wasser" = 2,
	"Strom" = 3,
	"Wärme" = 4,
	"Energiehandel" = 5,
}
export enum FDWAntragsteller{
	"Kleine und mittelständische Unternehmen (KMU)" = 1,
	"Große Unternehmen der gewerblichen Wirtschaft" = 2,
	"Unternehmen der Wohnwirtschaft" = 3,
	"Kirchen" = 4,
	"Vereine" = 5,
	"Stiftungen" = 6,
	"Kommunen" = 7,
	"Gemeinnützige Organisationen" = 8,
	"Natürliche Personen" = 9,
	"juristische Personen" = 10,
	"Verbände" = 11,
	"freiberuflich Tätige" = 12,
	"Wohnungsgenossenschaften" = 13,
	"Kommunale Gebietskörperschaften" = 14,
	"Kommunale Zweckverbände" = 15,
	"Kommunale Unternehmen" = 16,
	"Juristische Personen des öffentlichen Rechts" = 17,
	"Projektgesellschaften" = 18,
	"Agrarbetriebe" = 19,
	"Stadtwerke" = 20,
	"Bildungseinrichtungen" = 21,
	"sonstige Körperschaften des öffentlichen Rechts" = 22,
	"Wohnungseigentümergemeinschaft (WEG)" = 23,
	"Kontraktor" = 24,
}

export type FDWResultResponse = Array<FDWResultEntry>;

export class FDWResult extends Request<FDWResultResponse>{
    method : TRequestMethod = "POST";
    responseSchema : Schema = [{
        "fp_id": "string",
        "fp_name": "string",
        "fp_betrag": "string",
        "fp_organisation": "string",
        "region": "string",
        "gewerke_id": "string", //komma seperated list of numbers
        "kriterium_id": "string",
        "fp_type": "string"
    }];

    constructor (
        public session_id: string,
        public gemeinde_id: number,
        public gewerk: {[key: number]: Array<number>},
        public typ: "Altbau" | "Neubau",
        public wohngebaeude: boolean,
        public gewerbegebaeude: boolean,
        public gemeinnuetzig: boolean,
        public gebaeude: "Einfamilienhaus" | "Mehrfamilienhaus" | "",
        public baujahr: number,
        public grundversorger: boolean = false,
        public antragsteller: FDWAntragsteller | undefined = undefined,
        public evu1_id: number | undefined = undefined,
        public evu2_id: number | undefined = undefined,
        public lang: "de" | "fr" | "it" = "de",
        public nutzer: string | undefined = undefined,
    ){
        super();
    }

    // json() : string{
    //     const data = JSON.parse(JSON.stringify(this));
    //     data.wohngebaeude = this.wohngebaeude === true ? 1 : 0;
    //     data.gewerbegebaeude = this.gewerbegebaeude === true ? 1 : 0;
    //     data.gemeinnuetzig = this.gemeinnuetzig === true ? 1 : 0;
    //     data.grundversorger = this.grundversorger === true ? 1 : 0;
    //     delete data['method'];
    //     delete data['responseSchema'];
    //     Object.keys(data).forEach(key => {
    //         if (data[key] === undefined) {
    //             delete data[key];
    //         }
    //     });
    //     console.log("DATA:", data);
    //     return data;
    // }

    route() : string {
        return `${BASEURL}/fdw_result`;
    }
}