import { useQuestionizerStore } from "../types/state";
import Input from "./input";


export default function InputBaujahr (props: {id: number, label: string}){
  const baujahr = useQuestionizerStore((state) => state.baujahr);
  const setBaujahr = useQuestionizerStore((state) => state.setBaujahr);
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);

  const label_input = useQuestionizerStore((state) => state.translation.labels['input-baujahr--input']);
  
  return <div className={"input-"+props.id}>
       <Input<number> label={props.label || label_input} value={baujahr} onChange={(value)=>{
          //validation(value);
          const processed_value = parseInt(value.toString().replace(/\D+/,"").slice(0,4));
          setBaujahr(processed_value);
          updateValidation();
        }} type="text"/>
  </div>
}
