import { BASEURL, Request, TRequestMethod } from "./request.ts";
import { Schema } from "./validation.ts";

/*
Der Webservice fdw_fp ermittelt zu einer PLZ die zugehörigen Gemeinde-Parameter. 
Es wird ein Array von Gemeinden zurückgegeben. Das dabei zurückgegebene Objekt enthält
neben dem für die weitere Anfrage relevanten Parameter gemeinde_id auch noch 
Zusatzinformationen, wie der Name des Bundeslandes, des Verbundes, 
des Kreises und der Gemeinde selbst, die für die weiteren Abfragen nicht relevant sind, 
dem Onlinenutzer jedoch als Information angezeigt werden können.
*/

export interface Foerderprogramm{
    region: string,
    name: string,
    zielgruppe: string,
    kumulierbarkeit: string,
    adresse1: string,
    adresse2: string,
    ansprechpartner: string,
    strasse: string,
    plz: number,
    ort: string,
    tel1: string,
    tel2: string,
    fax: string,
    email: string,
    url: string,
    bemerkung: string,
    datum: string,
    betrag: string,
    type: string
}

export type FDWFpResponse = Foerderprogramm;

export class FDWFp extends Request<FDWFpResponse>{
    method : TRequestMethod = "GET";
    responseSchema : Schema = {
        "region": "string",
        "name": "string",
        "zielgruppe": "string",
        "kumulierbarkeit": "string",
        "adresse1": "string",
        "ansprechpartner": "string",
        "strasse": "string",
        "plz": "number",
        "ort": "string",
        "tel1": "string",
        "fax": "string",
        "email": "string",
        "url": "string",
        "bemerkung": "string",
        "datum": "string",
        "betrag": "string",
        "type": "string"
    }

    constructor (
        public session_id: string,
        public fp_id: number){
        super();
    }

    route() : string{
        return `${BASEURL}/fdw_fp/${this.session_id}/${this.fp_id}`;
    }
}