import { ReactNode } from "react";

type SelectValue = string | number;

export default function Select<T> (props: {value: T | undefined, children: ReactNode, onChange: (value: T) => void, label: string}){
  return (
    <div className={"select-input"}>
      <select className="select-input__input" value={props.value as SelectValue} onChange={(e)=> props.onChange(e.target.value as T)}>
        {props.children}
      </select> 
    </div>
    );
}