import { BASEURL, Request, TRequestMethod } from "./request.ts";
import { Schema } from "./validation.ts";

/*
Der Webservice fdw_Statistics gibt eine für diese Anfrage eindeutige ID zurück, 
welche für die weiteren Webservices zu der jeweiligen Anfrage mitgegeben werden muss.
*/

export type FDWSendFormdataResponse = string;

export class FDWSendFormdata extends Request<FDWSendFormdataResponse>{
    method : TRequestMethod = "POST";
    responseSchema : Schema = 'boolean';

    constructor (
        public session_id: string,
        public email: string,
        public data: {
            vorname: string,
            name: string,
            email?: string,
            telefon?: string,
        },
        public cc?: string,
        public subject?: string,
        ){
        super();
    }

    route() : string{
        return `${BASEURL}/fdw_send_formdata/`;
    }
}