import { useQuestionizerStore } from "../types/state.ts";
import Toggle from "./toggle.tsx";

export default function InputGewerk (props: {id: number, icon:string, icon_active?:string, label: string,info_title: string,info_text: string, deactivate_ids?:Array<number>}){
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);
  const gewerk = useQuestionizerStore((state) => state.gewerk[props. id]);
  const toggleGewerk = useQuestionizerStore((state) => state.toggleGewerk);

  return <Toggle id={props.id.toString()} label={props.label} info_title={props.info_title} info_text={props.info_text} icon={props.icon} icon_active={props.icon_active} value={gewerk} onChange={(value)=>{
      if (props.deactivate_ids !== undefined){
        props.deactivate_ids.forEach((id)=>{
          toggleGewerk(id, false);
        });
        toggleGewerk(props.id, true);
      }else{
        toggleGewerk(props.id, value);
      }
      
      updateValidation();
    }}/>
}
