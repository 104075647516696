import './index.scss'
import type {} from '@redux-devtools/extension' 
import Questionizer from './components/questionizer';
import { useEffect } from 'react';
import { useQuestionizerStore } from './types/state';

export default function App(props: {configPath: string | null | undefined}) {
  const config = useQuestionizerStore((state) => state.config);
  const loadConfig = useQuestionizerStore((state) => state.loadConfig);
  const requestStatistics = useQuestionizerStore((state) => state.requestStatistics);
  const sendStatisticsInterval = 3000; //3 seconds
  useEffect(()=>{
    if(!props.configPath){return;}
    if (config){ return; } 
    loadConfig(props.configPath);

    requestStatistics();
    const interval = setInterval(()=>{
      requestStatistics();
    }, sendStatisticsInterval);
    return ()=>{clearInterval(interval)};
  },[]);

  if (!props.configPath || !config) { return; }

  let style = config.style;
  let parsed_style = Object.keys(style)
    .map(element => [element, element.split(',').map(s => ".fbq "+s).join(',')])
    .map(element => `${element[1]}{${Object.keys(style[element[0]])
      .map( s => `${s.toString()}:${style[element[0]][s]}`)
      .join(';')}}`)
    .join(' ');
  
    return <>
        <style>{parsed_style}</style>
        <Root />
    </>
  }

function Root(){
  return <div className="questionizer-container w-full h-full flex flex-col font-sans">
      <Questionizer />
  </div>
}