import { BASEURL, Request, TRequestMethod } from "./request.ts";
import { Schema } from "./validation.ts";

/*
Der Webservice fdw_session gibt eine für diese Anfrage eindeutige ID zurück, 
welche für die weiteren Webservices zu der jeweiligen Anfrage mitgegeben werden muss.
*/

export interface FDWSessionResponse{
    session_id: string;
}

export class FDWSession extends Request<FDWSessionResponse>{
    method : TRequestMethod = "GET";
    responseSchema : Schema = {
        session_id: 'string'
    }

    constructor (
        public user: string,
        public password: string){
        super();
    }

    route() : string{
        return `${BASEURL}/fdw_session/${this.user}/${this.password}`;
    }
}