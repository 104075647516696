import { Json, Schema, ValidationResult, validateObject } from "./validation.ts";

export const BASEURL = 'https://restfd2.foerderdata.de';//'https://restfd2test.foerderdata.de';
export const APIUSER = "fd2_ws";
export const APIPASSWORD = "Ku71OpZ7ILmt3";

export type TRequestMethod = "GET" | "POST" | "PUT" | "DELETE";

interface RestResponse {
    error:number; // 0 = no error, 1 = error
    error_code:number;
    error_text:string;
    item:object;
}

export class Request<Res> {
    method: TRequestMethod = "GET";
    responseSchema : Schema = {};

    constructor (){}
    
    route() : string{
        return '';
    }

    json() : string{
        const data = JSON.parse(JSON.stringify(this));
        Object.keys(data).forEach(key => {
            if (data[key] === undefined) {
                delete data[key];
            }
        });
        delete data['method'];
        delete data['responseSchema'];
        
        return JSON.stringify(data)
    }

    validate (data: Json): ValidationResult {
        return validateObject(data, this.responseSchema);
    }

    headers() : Record<string | number | symbol, never>{
        return {};
    }

    async request() : Promise<Res>{
        const route = this.route();
        const method = this.method;
        try{
          const response = await fetch(route, {
                method: method,
                headers: this.headers(),
                ...(method == 'GET' ? {} : {body: this.json()}),
              });
              
            const json : unknown = await response.json();
            const validation = validateObject(json as Json, {
                error: "number",
                error_code: "number",
                error_text: "string",
            });
            if (!validation.valid) {
                throw new Error("maleformed response: " + JSON.stringify(json) + ' ' + validation.error.join(', '));
            }
        
            const data = json as RestResponse;
            if (!this.validate(data.item as Json)) {
                throw new Error(" maleformed data: " + ' ' + route + ' ' + JSON.stringify(data));
            }

            if (data["error"] == 1) {
                throw new Error(data["error_code"] + ' ' + route + ' ' + data["error_text"]);
            }
      
            return data.item as Res;
        }catch(e){
            throw e;
        }
      }
    
    //   async request_raw() : Promise<string>{
    //     const route = this.route();
    //     const method = this.method;
        
    //     try{
    //       const response = await fetch(route, {
    //             method: method,
    //             headers: this.headers(),
    //             ...(method == 'GET' ? {} : {body: this.json()}),
    //           });
              
    //         return await response.text();
    //     }catch(e){
    //         throw e;
    //     }
    //   }
}