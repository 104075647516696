import { BASEURL, Request, TRequestMethod } from "./request.ts";
import { Schema } from "./validation.ts";

/*
Der Webservice fdw_gid ermittelt zu einer PLZ die zugehörigen Gemeinde-Parameter. 
Es wird ein Array von Gemeinden zurückgegeben. Das dabei zurückgegebene Objekt enthält
neben dem für die weitere Anfrage relevanten Parameter gemeinde_id auch noch 
Zusatzinformationen, wie der Name des Bundeslandes, des Verbundes, 
des Kreises und der Gemeinde selbst, die für die weiteren Abfragen nicht relevant sind, 
dem Onlinenutzer jedoch als Information angezeigt werden können.
*/

export interface Gemeinde {
    gemeinde_id: number;
    gemeinde_name: string;
    verbund_name: string;
    kreis_name: string;
    land_name: string;
};

export type FDWGidResponse = Array<Gemeinde>;
export class FDWGid extends Request<FDWGidResponse>{
    method : TRequestMethod = "GET";
    responseSchema : Schema = [{
            gemeinde_id: 'string',
            gemeinde_name: 'string',
            verbund_name: "string",
            kreis_name: "string",
            land_name: "string"
        }]

    constructor (
        public session_id: string,
        public plz: string){
        super();
    }

    route() : string{
        return `${BASEURL}/fdw_gid/${this.session_id}/${this.plz}`;
    }
}