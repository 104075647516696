import { useQuestionizerStore } from "../types/state";

export default function LoadingIndicator (){
  const label = useQuestionizerStore((state) => state.translation.labels['loading']);

  return (
    <div className={"loading-indicator"}>
      <span className={"font-xl text-center"}>
       {label} 
       <Spinner />
      </span>
    </div>
    );
}

function Spinner (){
  return (
    <div className={"spinner"}></div>
    );
}