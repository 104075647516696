// export type Schema = Record<string, Schema | boolean>;


export type SchemaPrimitive = 'number' | 'string' | 'boolean';

export interface SchemaObject {
    [property: string ]: SchemaPrimitive | Array<SchemaPrimitive>| SchemaObject | Array<SchemaObject> ;
}

export type Schema = SchemaPrimitive | SchemaObject | Array<SchemaPrimitive> | Array<SchemaObject>;

export type JsonPrimitive = number | string | boolean;
export interface JsonObject {
    [property: string]: JsonPrimitive | Array<JsonPrimitive> | Array<JsonObject> | JsonObject;
}

export type Json = JsonPrimitive | JsonObject | Array<JsonPrimitive> | Array<JsonObject>;
export interface ValidationResult {
    valid: boolean;
    error: Array<string>;
}

export function validateObject(data : Json, schema : Schema) : ValidationResult{
    return Object.keys(schema).flatMap( (key) =>  {
        let s;
        if (Array.isArray(schema) || typeof schema === 'string' || typeof schema === 'boolean' || typeof schema === 'number'){
            s = schema
        }else{
            s = schema[key];
        }

        let d;
        if (Array.isArray(data) || typeof data === 'string' || typeof data === 'boolean' || typeof data === 'number'){
            d = data
        }else{
            d = data[key];
        }

        if ((s === 'number' && (typeof d === 'number' || d == "")) ||
          (s === 'string' && typeof d === 'string') ||
          (s === 'boolean' && typeof d === 'boolean')){
            return {valid: true, error: []};
        }
        if (Array.isArray(s) && Array.isArray(d) && s.length > 0) {
            const array_schema = s[0];
            //console.log("ARRAY: ",s, d, array_schema);
            if (d.every((item) => validateObject(item, array_schema).valid)){
                return {valid: true, error: []};
            }else{
                return {valid: false, error: [`${key} is not valid`]};
            }
        }

        if (typeof s === 'object' && typeof d === 'object') {
            return validateObject(d, s);
        }

        return {valid: false, error: [`${key} is not valid`]};
    }).reduce((acc, cur) => ({
        valid: acc.valid && cur.valid, 
        error: [...acc.error, ...cur.error]}
    ));//.every((test) => test.valid);
}