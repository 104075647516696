import errors from './errors.json';
import labels from './labels.json';

export interface TranslationTable{
    errors: Record<string, string>;
    labels: Record<string, string>
}

export default {
    errors,
    labels
}