import { useQuestionizerStore } from "../types/state";

export default function Errors(){
  const getCurrentStepErrors = useQuestionizerStore((state) => state.getCurrentStepErrors());
  const isCurrentStepValid = useQuestionizerStore((state) => state.isCurrentStepValid());

  const currentErrors = getCurrentStepErrors.filter(e => e.length > 0);

  if (currentErrors.length == 0 || isCurrentStepValid){
    return <div></div>
  }

  return <div className="errors step_buttons__errors">
      <div className="icon icon-info errors__icon"></div>
      <div className="errors__list">
        { currentErrors.map(e => <span key={e}>{e}</span>) }
      </div>
    </div>;
}