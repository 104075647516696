import { useEffect } from "react";
import { useQuestionizerStore } from "../types/state";
import StepButtons from "./step-buttons";
import StepNavigation from "./step-navigation";

import LoadingIndicator from "./loading-indicator.tsx";
//import QuestionizerResult from "./questionizer-result.tsx";
import QuestionizerForm from "./questionizer-form.tsx";

export default function Questionizer(){
  //const step = useQuestionizerStore((state) => state.step);
  //const step_count = useQuestionizerStore((state) => state.stepsCount());

  const config = useQuestionizerStore((state) => state.config);
  const session_id = useQuestionizerStore((state) => state.session_id);
  const session_id_timestamp = useQuestionizerStore((state) => state.session_id_timestamp);
  const requestSessionId = useQuestionizerStore((state) => state.requestSessionId);
  const pdf_link = useQuestionizerStore((state) => state.pdf_link);

  useEffect(() => {
    if (config?.use_pdf_download && pdf_link){
      window.open(pdf_link!);
    }
  }, [pdf_link])

   useEffect(() => {
    if(session_id === undefined || session_id_timestamp === undefined || (session_id_timestamp + 43200000) < Date.now()){
        requestSessionId();
    }
  }, [config]);
  
  if (!config || !session_id){
    return <LoadingIndicator />
  }

 // if (step == step_count){
    // if(config.usePdfDownload && pdf_link){
    //   return (
    //     <div>
    //         <StepNavigation />
    //         <QuestionizerResult />
    //         <LoadingIndicator></LoadingIndicator>
    //         <StepButtons />
    //     </div>);
    // }else{
   //   return (
    //  <div>
     //     <StepNavigation />
      //    <QuestionizerResult />
     //     <StepButtons />
     //     {config.info_bottom_result && <p className="form__info-bottom">{config.info_bottom_result}</p>}
    //  </div>);
    // }
  //}else{
    return (
        <div>
            {config.info_top && <p className="form__info-top">{config.info_top}</p>}
            <StepNavigation />
            <QuestionizerForm />
            <StepButtons />
            {config.info_bottom && <p className="form__info-bottom">{config.info_bottom}</p>}
        </div>
    ); 
 // }
}
