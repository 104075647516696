import { FDWAntragsteller } from "../../../restfd2/fdw_result.ts";
import { useQuestionizerStore } from "../types/state.ts";
import Toggle from "./toggle.tsx";

// export default function InputAntragsteller (props: {id: number, label: string}){
//   const antragsteller = useQuestionizerStore((state) => state.antragsteller);
//   const setAntragsteller = useQuestionizerStore((state) => state.setAntragsteller);
//   const setValidation = useQuestionizerStore((state) => state.setValidation);
  
//   return <div>
//     <label>{props.label}</label>
//     <input type="text" value={""} onChange={()=>{
//       setValidation(props.id, {message:"", valid: true});
//     }}/>
//   </div>
// }

export default function InputAntragsteller (props: {id: number, antragsteller_type: Array<FDWAntragsteller>, icon:string, icon_active?:string,  label: string}){
  const updateValidation = useQuestionizerStore((state) => state.updateValidation);
  const antragsteller = useQuestionizerStore((state) => state.antragsteller);
  const setAntragsteller = useQuestionizerStore((state) => state.setAntragsteller);

  return <>
    <Toggle id={props.id.toString()} label={props.label} icon={props.icon} icon_active={props.icon_active} value={antragsteller == props.antragsteller_type} onChange={(_)=>{
      if (antragsteller !== props.antragsteller_type){
        setAntragsteller(props.antragsteller_type);
        updateValidation();
      }
      
    }}/>
  </>
}
